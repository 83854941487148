<template>
  <div
    data-app="true"
    class="application"
  >
    <div v-if="!inMaintenance">
      <CustomOverlay
        v-if="showOverlay"
        @clicked="closeOverlay"
      />
      <template v-if="userDetails.data && userDetails.currencyName && permissionsLoaded">
        <AppBar
          v-show="!iframe"
          :navigation="navigation"
          :notifications="notifications"
          :search-bar="searchBarState.getValue().show"
          :selected-filter="searchBarState.getValue().type"
          :customer-id="customerDetails.id"
          :is-in-mobile-app="isInMobileApp"
          @nav-bar-clicked="openDrawer"
          @notifications-clicked="notificationsClicked"
          @search-bar-clicked="searchBarClicked"
          @filter-selected="filterSelected"
          @close-notifications="closeNotifications"
          @close-search-bar-clicked="closeSearchBarClicked"
        />
        <NavigationDrawer
          v-if="(breakpoints.mdAndDown.includes(mq.current) || isInMobileApp) && !iframe"
          :items="navigation"
          :drawer="drawer"
          @close-drawer="closeDrawer"
          @nav-draw-state="navDrawState"
          @account-menu-clicked="openAccountMenu"
        />
      </template>
      <TagModal
        v-if="tags.showModal"
        :id="tags.id"
        :entity="tags.entity.name"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onUpdated, onUnmounted, onBeforeMount } from 'vue';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';
import AppBar from '@/shared/components/AppBar.vue';
import NavigationDrawer from '@/shared/components/NavigationDrawer/NavigationDrawer.vue';
import { CustomOverlay } from '@sales-i/dsv3';
import TagModal from '@/shared/components/Tags/TagModal.vue';
import { useStore } from 'vuex';
import {
  CHECK_PERMISSIONS,
  SET_ENTITY,
  GET_USER_DETAILS,
  GET_CURRENCY,
  CLEAR_SEARCH_BAR_RESULTS,
  SET_VISIBLE,
  CLEAR_VISIBLE,
  SET_MAINTENANCE_MODE,
  SET_IFRAME,
  GET_TENANT,
} from '@/shared/store/actionType';

import useNavigation from '@/shared/composables/useNavigation';

import { enablePageScroll, disablePageScroll } from '@/shared/utils/document';
import { getPlatform } from '@/shared/utils/platform';
import { useRoute } from 'vue-router';

import router from '@/routers';
import { useHead } from '@unhead/vue';

import {
  userDetailsState,
  currencyState,
  tenantState,
  searchBarState,
  permissionsState,
  showSearchBar,
  hideSearchBar,
  setSearchBarType,
} from '@sales-i/utils';


useHead({
  titleTemplate: 'sales-ai - %s',
});
const mq = useMq();
const drawer = ref(false);
const store = useStore();
const vroute = useRoute();

const searchBar = ref({});

const { navigation } = useNavigation();

const setPermissions = p => store.commit(`pbac/${CHECK_PERMISSIONS}`, p);
const clearSearchResults = () => store.dispatch(`searchBarResults/${CLEAR_SEARCH_BAR_RESULTS}`);
const setUserDetails = data => store.commit(`userDetails/${GET_USER_DETAILS}`, data);
const setCurrency = currency => store.commit(`userDetails/${GET_CURRENCY}`, currency);
const setTenantDetails = tenant => store.commit(`system/${GET_TENANT}`, tenant);
const setMaintenanceMode = bool => store.dispatch(`system/${SET_MAINTENANCE_MODE}`, bool);
const setIframe = bool => store.dispatch(`system/${SET_IFRAME}`, bool);
const setSystemEntity = value => store.dispatch(`system/${SET_ENTITY}`, value);
const setTagsModalVisibility = params => store.dispatch(`tags/${SET_VISIBLE}`, params);
const showNotification = () => store.dispatch(`notifications/${SET_VISIBLE}`);
const hideNotification = () => store.dispatch(`notifications/${CLEAR_VISIBLE}`);
const tenantDetails = computed(() => store.state.system.tenant.data);
const notifications = computed(() => store.state.notifications?.visible);
const customerDetails = computed(() => store.state.crm.customers.selected.data);
const permissionsGranted = computed(() => store.state.pbac.permissionsGranted);
const platform = computed(() => store.state.platform);
const userDetails = computed(() => store.state.userDetails);
const showOverlay = computed(
  () => (searchBar.value.overlay || notifications.value) && platform.value.data !== 'mobile'
);
const isInMobileApp = computed(() => platform.value.data === 'mobile');
const permissionsLoaded = computed(() => Object.keys(permissionsGranted.value).length > 0);
const tags = computed(() => store.state.tags);
const inMaintenance = computed(() => store.state.system.maintenance_mode);
const iframe = computed(() => store.state.system.iframe);

/** Subscribe */
const rxud = userDetailsState.subscribe(({ data }) => {
  setUserDetails(data);
});

const rxc = currencyState.subscribe(({ name }) => {
  setCurrency(name);
});

const rxt = tenantState.subscribe(tenant => {
  setTenantDetails(tenant);
});

const rxsb = searchBarState.subscribe(sb => {
  searchBar.value = sb;
});

const rxp = permissionsState.subscribe(p => {
  setPermissions(p);
});

watch(vroute, () => {
  closeSearchBarClicked();
  setEntity();
});

onBeforeMount(async () => {
  try {
    await router.isReady();
    checkIfInMaintenance();
    checkIfIframe();
    getPlatform();
    setEntity();
    window.showTagModal = showTagModal;
    // onSuccess
  } catch (err) {
    // onError
  }
});
onUpdated(() => {
  // little hack to fix a bug where it doesn't show if down the page a bit
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
  const { show, type } = searchBar.value;
  if ((show && type) || notifications.value) {
    disablePageScroll();
  } else {
    enablePageScroll();
  }
});
onUnmounted(() => {
  rxud.unsubscribe();
  rxc.unsubscribe();
  rxt.unsubscribe();
  rxsb.unsubscribe();
  rxp.unsubscribe();
});

function checkIfInMaintenance() {
  let { query } = vroute;
  if (query && query.maintenance) {
    setMaintenanceMode(query.maintenance === 'true');
  }
}
function checkIfIframe() {
  const { path } = vroute;
  setIframe(path && path.includes('iframe'));
}
function closeDrawer() {
  drawer.value = false;
}
function closeNotifications() {
  hideNotification();
}
function closeOverlay() {
  hideSearchBar();
  closeNotifications();
}
function closeSearchBarClicked() {
  hideSearchBar();
  clearSearchResults();
}
function filterSelected({ value }) {
  setSearchBarType(value);
}
function navDrawState(open) {
  drawer.value = open;
}
function notificationsClicked() {
  closeOverlay();
  showNotification();
}
function openAccountMenu() {
  closeOverlay();
  if (platform.value.data === 'mobile') {
    try {
      const { first_name, last_name, email, roles } = userDetails.value?.data;
      const accountData = {
        first_name,
        last_name,
        full_name: `${first_name} ${last_name}`.trim(),
        email,
        roles,
        tenant: tenantDetails.value.name || '',
      };
      window.webkit.messageHandlers.callbackHandler.postMessage(JSON.stringify(accountData));
    } catch (err) {
      // onError
    }
  }
}
function openDrawer() {
  drawer.value = true;
}
function searchBarClicked() {
  closeOverlay();
  showSearchBar();
}
function setEntity() {
  let { path } = vroute;
  let parts = path.split('/');
  if (parts[2]) {
    setSystemEntity(parts[2].toLowerCase());
  }
}
function showTagModal(id, entity) {
  setTagsModalVisibility({
    id,
    entity,
  });
}
</script>

<style>
[id='single-spa-application:@sales-i/header'] {
  position: sticky;
  top: 0;
  z-index: 102; /* $headerZIndex + 1 to account for enquiry nav covering secondary nav in flow */
}
</style>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
main {
  display: flex;
  flex-flow: column nowrap;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-bottom: var(--spacing-12);
  }
}
</style>
